import React, { FunctionComponent } from 'react';

import withIconContainer from './withIconContainer';

const IconUsdCoin: FunctionComponent = () => (
    <svg
        aria-labelledby="iconUsdCoinTitle"
        fill="none"
        height="48"
        viewBox="0 0 70 48"
        width="70"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title id="iconUsdCoinTitle">USD Coin</title>
        <rect fill="white" height="47" rx="5.5" stroke="#D9D9D9" width="69" x="0.5" y="0.5" />
        <g clipPath="url(#clip0_1486_618)">
            <path
                d="M35 40C43.8667 40 51 32.8667 51 24C51 15.1333 43.8667 8 35 8C26.1333 8 19 15.1333 19 24C19 32.8667 26.1333 40 35 40Z"
                fill="#2775CA"
            />
            <path
                d="M39.4 26.5333C39.4 24.2 38 23.4 35.2 23.0667C33.2 22.8 32.8 22.2667 32.8 21.3333C32.8 20.3999 33.4667 19.8 34.8 19.8C36 19.8 36.6667 20.2 37 21.2C37.0667 21.4 37.2667 21.5333 37.4667 21.5333H38.5333C38.8 21.5333 39 21.3333 39 21.0667V21C38.7333 19.5333 37.5333 18.4 36 18.2667V16.6667C36 16.4 35.8 16.2 35.4667 16.1333H34.4667C34.2 16.1333 34 16.3333 33.9333 16.6667V18.2C31.9333 18.4667 30.6667 19.8 30.6667 21.4667C30.6667 23.6667 32 24.5333 34.8 24.8667C36.6667 25.2 37.2667 25.6 37.2667 26.6667C37.2667 27.7335 36.3333 28.4667 35.0667 28.4667C33.3333 28.4667 32.7333 27.7333 32.5333 26.7333C32.4667 26.4667 32.2667 26.3333 32.0667 26.3333H30.9333C30.6667 26.3333 30.4667 26.5333 30.4667 26.8V26.8667C30.7333 28.5333 31.8 29.7333 34 30.0667V31.6667C34 31.9333 34.2 32.1333 34.5333 32.2H35.5333C35.8 32.2 36 32 36.0667 31.6667V30.0667C38.0667 29.7333 39.4 28.3333 39.4 26.5333Z"
                fill="white"
            />
            <path
                d="M31.6 33.5333C26.4 31.6667 23.7333 25.8667 25.6667 20.7333C26.6667 17.9333 28.8667 15.8 31.6 14.8C31.8667 14.6667 32 14.4667 32 14.1333V13.2C32 12.9333 31.8667 12.7333 31.6 12.6667C31.5333 12.6667 31.4 12.6667 31.3333 12.7333C25 14.7333 21.5333 21.4667 23.5333 27.8C24.7333 31.5333 27.6 34.4 31.3333 35.6C31.6 35.7333 31.8667 35.6 31.9333 35.3333C32 35.2667 32 35.2 32 35.0667V34.1333C32 33.9333 31.8 33.6667 31.6 33.5333ZM38.6667 12.7333C38.4 12.6 38.1333 12.7333 38.0667 13C38 13.0667 38 13.1333 38 13.2667V14.2C38 14.4667 38.2 14.7333 38.4 14.8667C43.6 16.7333 46.2667 22.5333 44.3333 27.6667C43.3333 30.4667 41.1333 32.6 38.4 33.6C38.1333 33.7333 38 33.9333 38 34.2667V35.2C38 35.4667 38.1333 35.6667 38.4 35.7333C38.4667 35.7333 38.6 35.7333 38.6667 35.6667C45 33.6667 48.4667 26.9333 46.4667 20.6C45.2667 16.8 42.3333 13.9333 38.6667 12.7333Z"
                fill="white"
            />
        </g>
        <defs>
            <g clipPath="url(#clip0_1486_618)">
                <rect fill="white" height="32" transform="translate(19 8)" width="32" />
            </g>
        </defs>
    </svg>
);

export default withIconContainer(IconUsdCoin);
