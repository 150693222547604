import React, { FunctionComponent } from 'react';

import withIconContainer from './withIconContainer';

const IconCardHipercard: FunctionComponent = () => (
    <svg
        aria-labelledby="iconCardHiperTitle"
        fill="none"
        height="48"
        viewBox="0 0 70 48"
        width="70"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title id="iconCardHiperTitle">Hiper</title>
        <rect fill="#D26528" height="47" rx="5.5" stroke="#D9D9D9" width="69" x="0.5" y="0.5" />
        <g clipPath="url(#clip0_1486_337)">
            <mask
                height="46"
                id="mask0_1486_337"
                maskUnits="userSpaceOnUse"
                width="74"
                x="-2"
                y="1"
            >
                <path
                    d="M66.745 1H3.255C0.790425 1 -1.2075 2.99793 -1.2075 5.4625V42.2125C-1.2075 44.6771 0.790425 46.675 3.255 46.675H66.745C69.2096 46.675 71.2075 44.6771 71.2075 42.2125V5.4625C71.2075 2.99793 69.2096 1 66.745 1Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_1486_337)">
                <path
                    d="M21.14 15.2274V20.8799H15.575V15.2274H12.1625V29.9274H15.575V23.7674H21.175V29.9274H24.5V15.2274H21.1225H21.14ZM46.76 23.3824C46.7685 22.8232 46.9909 22.2884 47.3815 21.8881C47.772 21.4878 48.3011 21.2522 48.86 21.2299C49.1302 21.2329 49.3967 21.2928 49.6422 21.4056C49.8877 21.5184 50.1067 21.6816 50.285 21.8847C50.4633 22.0877 50.5968 22.326 50.6769 22.5841C50.757 22.8421 50.7819 23.1141 50.75 23.3824H46.76ZM53.7775 25.6574C53.8555 25.2242 53.8965 24.7851 53.9 24.3449C53.9 21.7024 52.57 19.0249 49.0525 19.0249C48.3101 19.0093 47.5726 19.1473 46.886 19.4301C46.1995 19.7129 45.5788 20.1346 45.0629 20.6686C44.5469 21.2026 44.1469 21.8374 43.8879 22.5333C43.6288 23.2292 43.5163 23.971 43.5575 24.7124C43.5575 28.0549 45.6575 30.1374 49.3675 30.1374C50.7028 30.1635 52.0315 29.944 53.2875 29.4899L52.85 27.2499C51.8814 27.5508 50.8717 27.6984 49.8575 27.6874C48.2825 27.6874 46.8825 27.0224 46.7775 25.6574H53.7775Z"
                    fill="white"
                />
                <mask
                    height="11"
                    id="mask1_1486_337"
                    maskUnits="userSpaceOnUse"
                    width="8"
                    x="54"
                    y="19"
                >
                    <path
                        d="M61.6525 29.9274V19.0249H54.8975V29.9449H61.6525V29.9274Z"
                        fill="white"
                    />
                </mask>
                <g mask="url(#mask1_1486_337)">
                    <path
                        d="M54.985 29.9274H58.3625V24.5024C58.3563 24.2563 58.3739 24.0102 58.415 23.7674C58.5318 23.252 58.8327 22.7969 59.2613 22.4876C59.6898 22.1783 60.2165 22.036 60.7425 22.0874C61.0479 22.0882 61.3525 22.1175 61.6525 22.1749V19.0599C61.4151 19.024 61.1747 19.0123 60.935 19.0249C60.2772 19.0437 59.6414 19.2658 59.1149 19.6606C58.5884 20.0555 58.1972 20.6037 57.995 21.2299H57.9075L57.8025 19.2524H54.8975C54.9325 20.1799 54.985 21.1949 54.985 22.7699V29.9274Z"
                        fill="white"
                    />
                </g>
                <mask
                    height="5"
                    id="mask2_1486_337"
                    maskUnits="userSpaceOnUse"
                    width="4"
                    x="26"
                    y="14"
                >
                    <path d="M29.6975 14.6324H26.2325V18.0449H29.6975V14.6324Z" fill="white" />
                </mask>
                <g mask="url(#mask2_1486_337)">
                    <path
                        d="M27.965 14.6324C28.2983 14.6428 28.6212 14.751 28.8933 14.9437C29.1655 15.1364 29.3749 15.4049 29.4953 15.7159C29.6158 16.0268 29.642 16.3664 29.5707 16.6921C29.4994 17.0179 29.3337 17.3154 29.0944 17.5476C28.855 17.7798 28.5526 17.9363 28.2248 17.9976C27.897 18.059 27.5585 18.0225 27.2513 17.8926C26.9442 17.7627 26.6821 17.5452 26.4978 17.2673C26.3135 16.9894 26.2151 16.6634 26.215 16.3299C26.2172 16.1032 26.2644 15.8792 26.3538 15.6708C26.4432 15.4625 26.5731 15.2739 26.7358 15.1161C26.8986 14.9582 27.091 14.8342 27.302 14.7512C27.513 14.6682 27.7383 14.6278 27.965 14.6324Z"
                        fill="#FDE600"
                    />
                </g>
                <path
                    d="M26.215 19.2349H29.6975V25.2549C29.6702 25.5356 29.7027 25.8189 29.793 26.0861C29.8832 26.3532 30.029 26.5983 30.2208 26.805C30.4126 27.0117 30.6461 27.1754 30.9058 27.2852C31.1655 27.3951 31.4456 27.4487 31.7275 27.4424C31.7275 24.7299 31.7275 21.9474 31.6225 19.2349H34.5275L34.7025 20.8099C36.0675 18.1149 40.39 18.7099 41.8425 20.9849C43.3475 23.3299 43.855 30.0499 36.785 30.0499H35.14L35.1575 34.1449H31.6925V30.0499C27.9475 30.0499 26.215 27.7049 26.215 25.2549V19.2349ZM35.0875 27.4599H36.785C37.0972 27.4999 37.4143 27.4726 37.715 27.3798C38.0157 27.2871 38.2931 27.131 38.5285 26.9221C38.764 26.7132 38.9519 26.4564 39.0798 26.1688C39.2077 25.8812 39.2725 25.5696 39.27 25.2549C39.27 24.1349 39.1825 21.6149 37.0825 21.6149C34.6675 21.6149 35.0525 24.7649 35.07 26.3924L35.0875 27.4424V27.4599Z"
                    fill="white"
                />
            </g>
        </g>
        <defs>
            <clipPath id="clip0_1486_337">
                <rect fill="white" height="45.5" transform="translate(0 1)" width="70" />
            </clipPath>
        </defs>
    </svg>
);

export default withIconContainer(IconCardHipercard);
