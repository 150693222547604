import React, { FunctionComponent } from 'react';

import withIconContainer from './withIconContainer';

const IconBitCoin: FunctionComponent = () => (
    <svg
        aria-labelledby="iconBitCoinTitle"
        fill="none"
        height="48"
        viewBox="0 0 70 48"
        width="70"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title id="iconBitCoinTitle">BitCoin</title>
        <rect fill="white" height="47" rx="5.5" stroke="#D9D9D9" width="69" x="0.5" y="0.5" />
        <path
            clipRule="evenodd"
            d="M51.2539 24.1292C51.2539 33.0371 44.033 40.2584 35.1255 40.2584C26.2181 40.2584 18.9972 33.0371 18.9972 24.1292C18.9972 15.2213 26.2181 8 35.1255 8C44.033 8 51.2539 15.2213 51.2539 24.1292ZM39.0003 18.1366C41.2438 18.9071 42.885 20.0607 42.563 22.2077C42.3292 23.7799 41.4544 24.5403 40.2922 24.8065C41.8867 25.6335 42.4302 27.2029 41.9252 29.1005C40.9664 31.8314 38.6875 32.0613 35.6563 31.4905L34.9203 34.4261L33.1435 33.9844L33.8694 31.0882C33.4087 30.9742 32.938 30.8522 32.453 30.7221L31.7241 33.6325L29.9493 33.1908L30.6843 30.2491L27.1054 29.3496L27.9883 27.3226C27.9883 27.3226 29.2983 27.6685 29.2801 27.6433C29.7833 27.7673 30.007 27.4406 30.0951 27.2238L32.0896 19.256C32.1118 18.8798 31.9812 18.4059 31.2614 18.2264C31.2887 18.2072 29.9706 17.9067 29.9706 17.9067L30.4434 16.0158L34.0273 16.9003L34.7563 13.9919L36.5321 14.4336L35.8183 17.2845C36.2962 17.3924 36.776 17.5023 37.2428 17.6183L37.9515 14.7855L39.7283 15.2272L39.0003 18.1366ZM34.7489 22.9882C35.9592 23.3091 38.5929 24.0074 39.052 22.1765C39.5203 20.3033 36.9616 19.7376 35.7087 19.4606L35.7087 19.4606L35.7086 19.4606C35.5667 19.4292 35.4415 19.4015 35.3394 19.3761L34.4546 22.9117C34.5389 22.9325 34.6379 22.9588 34.7489 22.9882ZM33.3776 28.6807C34.8272 29.0626 37.9977 29.8977 38.5022 27.8803C39.0186 25.8175 35.9446 25.1305 34.4447 24.7952L34.4442 24.7951C34.2768 24.7577 34.129 24.7247 34.0081 24.6946L33.0321 28.5913C33.1312 28.6158 33.2473 28.6464 33.3772 28.6806L33.3776 28.6807Z"
            fill="#F7931A"
            fillRule="evenodd"
        />
    </svg>
);

export default withIconContainer(IconBitCoin);
