import React, { FunctionComponent } from 'react';

import withIconContainer from './withIconContainer';

const IconCardDankort: FunctionComponent = () => (
    <svg
        aria-labelledby="iconCardDankortTitle"
        fill="none"
        height="48"
        viewBox="0 0 70 48"
        width="70"
        xmlns="http://www.w3.org/2000/svg"
    >
        <title id="iconCardDankortTitle">Dankort</title>
        <rect fill="white" height="47" rx="5.5" stroke="#D9D9D9" width="69" x="0.5" y="0.5" />
        <path
            d="M21.9265 6C17.1416 6.03894 12.5674 7.97378 9.20699 11.3803C5.84654 14.7867 3.97411 19.3868 4.00027 24.1718C4.00027 34.2072 12.0384 42.36 21.9265 42.36H46.0737C55.9618 42.36 64 34.2072 64 24.1882C64 14.1528 55.9618 6 46.0737 6H21.9265Z"
            fill="white"
        />
        <path
            d="M51.2798 22.4202L58.1065 31.4569C59.5945 29.0527 60.3773 26.2789 60.3657 23.4515C60.3768 20.4841 59.5111 17.5795 57.8773 15.1023L51.2798 22.4202ZM27.7219 14.4638C33.55 14.4638 37.9374 15.8718 38.3958 20.5375L44.5186 14.4802H56.7313C55.3421 12.9424 53.6443 11.7146 51.7488 10.8768C49.8532 10.0391 47.8024 9.61015 45.73 9.61803H22.254C20.1883 9.61268 18.1446 10.0413 16.2551 10.876C14.3656 11.7108 12.6725 12.9331 11.2854 14.4638H27.7383H27.7219ZM17.0644 20.5375L14.887 25.989H23.5637C26.1503 25.989 26.9197 25.0068 27.4927 23.1241C28.0657 21.2578 26.6414 20.5375 24.8733 20.5375H17.0644ZM45.7464 32.8976L39.0997 24.1718C37.8719 30.1309 33.861 32.8976 26.314 32.8976H11.269C12.6255 34.6999 14.3807 36.1639 16.3971 37.175C18.4136 38.1861 20.6367 38.7168 22.8925 38.7257H46.9415C51.6399 38.7257 55.8309 36.4337 58.5485 32.8976H45.7464Z"
            fill="#ED1C24"
        />
    </svg>
);

export default withIconContainer(IconCardDankort);
